import * as Sentry from "@sentry/sveltekit";
import type { HandleClientError } from '@sveltejs/kit';

Sentry.init({
	dsn: "https://2084d1b0ce76c1d9eea8c98461134884@o4506870239920128.ingest.us.sentry.io/4506870242476032",
	integrations: [Sentry.replayIntegration()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

export const handleError: HandleClientError = ({ error, event }) => {
	if (!(error && error.status === 404)) {
		const errorId = crypto.randomUUID();
		Sentry.captureException(error, {
			contexts: { sveltekit: { event, errorId } },
		});

		return {
			message: "Une erreur inconnue est survenue, veuillez réessayer plus tard. Si le problème persiste, contactez le support.",
			errorId,
		}
	}
};
